import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useArchivePatientMutation } from "apollo/hooks/mutations";
import { TRACK_EVENTS } from "core/consts";
import { AUCTION_RUNNING_STATUSES } from "core/model/auctions";
import { Patient } from "core/types";
import { ArrowRightIcon } from "ds/icons";
import InfoChip from "ds_legacy/components/InfoChip";
import RSButton from "ds_legacy/components/RSButton";
import { DISABLED_COLOR, PRIMARY_COLOR } from "ds_legacy/materials/colors";
import { FONT_SIZE_13, FONT_SIZE_17 } from "ds_legacy/materials/typography";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { useCareseekerNavigationHandlers, useModal } from "dsl/hooks";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";
import Translations from "translations/types";

function ArchivePatientModal({
  onClose,
  open,
  patient,
}: {
  onClose: () => void;
  open: boolean;
  patient: Patient;
}) {
  const translations = useTranslations();
  const { trackEvent } = useTracking();
  const toast = useToast();
  const navigationHandlers = useCareseekerNavigationHandlers();

  const [archivePatient, queryProgress] = useArchivePatientMutation({
    id: patient.id,
    onCompleted: () => {
      toast({
        message: translations.timeline.patientArchivedNotification,
        color: "primary",
      });
      trackEvent({ name: TRACK_EVENTS.ARCHIVE_PATIENT_PATIENT_HOME });
      navigationHandlers.goToTodo();
    },
    onError: () => {
      toast({
        message: translations.auctionRequest.tryAgain,
        color: "primary",
      });
    },
  });

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle>
        {translations.patient.deletePatient.confirmText({
          patientName: patient?.user_id || "",
        })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translations.search.archivePatientDescription}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" className="cancel" onClick={onClose}>
          {translations.actions.cancel}
        </Button>
        <RSButton
          className="confirm"
          color="primary"
          id="archive"
          inverted
          loading={queryProgress}
          onClick={() => archivePatient()}
          variant="text"
        >
          {translations.actions.archiveButton}
        </RSButton>
      </DialogActions>
    </Dialog>
  );
}

export function ArchivePatient({
  patient,
  translations,
}: {
  patient: Patient;
  translations: Translations;
}) {
  const [open, setOpen] = useModal();

  if (patient.archived)
    return (
      <InfoChip
        label={translations.timeline.patientArchived}
        color="primary"
        variant="outlined"
      />
    );

  const canArchive = !patient.auctions?.some((auction) =>
    AUCTION_RUNNING_STATUSES.includes(auction?.status || -1),
  );
  return (
    <>
      <Button
        variant="text"
        onClick={() => {
          if (!canArchive) return;
          setOpen(true);
        }}
        sx={{
          display: "flex",
          color: canArchive ? PRIMARY_COLOR : DISABLED_COLOR,
          fontSize: FONT_SIZE_13,
          justifyContent: "center",
        }}
        disabled={!canArchive}
        data-testid="archive-patient"
      >
        {translations.patient.deletePatient.title}
        <ArrowRightIcon
          style={{ fontSize: FONT_SIZE_17 }}
          size={FONT_SIZE_17}
        />
      </Button>

      <ArchivePatientModal
        open={open}
        patient={patient}
        onClose={() => setOpen(false)}
      />
    </>
  );
}
