import { GET_TRANSITIONAL_CARE } from "core/apollo/graphql";
import { SEARCH_ACTION_GENERATE_TRANSITIONAL_CARE_FORM } from "core/consts";
import { getErrorMessage } from "core/model/utils/errors";
import {
  Auction,
  TransitionalCare,
  TransitionalCareFormData,
} from "core/types";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { scrollToTop } from "dsl/hooks/useScrollTo";
import useSearchAction from "dsl/hooks/useSearchAction";
import { useCallback, useMemo } from "react";
import {
  SimpleFormRenderProps,
  composeValidation,
  convertEncryptedFieldIn,
  convertEncryptedFieldOut,
  validateModel,
} from "react-forms-state";
import { FormContainerState } from "react-forms-state/src/FormContainer";
import { useTranslations } from "translations";
import Translations from "translations/types";
import { ExitPreventionWrapper } from "../../utils";
import { TransitionalCareFormPresenter } from "./FormSections";
import { transitionalCareModelDefinition } from "./modelDefinition";

const useGetOnSubmit = ({ auction }: { auction: Auction }) => {
  const translations = useTranslations();
  const [
    generateTransitionalCareForm,
    generateTransitionalCareFormProgress,
    resetProgress,
  ] = useSearchAction({
    actionType: SEARCH_ACTION_GENERATE_TRANSITIONAL_CARE_FORM,
  });
  const toast = useToast();

  const onSubmit = useCallback(
    async (
      formData: TransitionalCareFormData,
      formState: FormContainerState,
    ) => {
      if (!formState.dirty) return;

      const updatedAuction = {
        ...auction,
        transitional_care: {
          form_data: convertEncryptedFieldOut(JSON.stringify(formData)),
        },
      } as Auction;
      await generateTransitionalCareForm({
        auction,
        context: {
          auction: updatedAuction,
        },
        onCompleted: (generated, client) => {
          if (!generated) return;

          client.writeQuery({
            query: GET_TRANSITIONAL_CARE,
            variables: {
              auctionId: auction.id,
              patientId: auction.patient.id,
            },
            data: {
              auction: {
                id: auction.id,
                transitional_care: generated.transitional_care,
                seald_encryption_context: generated.seald_encryption_context,
                session_key_context: generated.session_key_context,
              },
            },
          });
          toast({
            message: translations.patientForms.transitionalCareForm.pdfCreated,
            action: {
              label: translations.patientForms.transitionalCareForm.toTheTop,
              onClick: () => scrollToTop(),
            },
            color: "success",
          });
          resetProgress();
        },
        onError: (err) => {
          console.error(`error saving rehab forms - ${getErrorMessage(err)}`);
          toast({
            message: translations.auctionRequest.tryAgain,
            color: "danger",
          });
        },
      });
    },
    [convertEncryptedFieldOut, generateTransitionalCareForm, auction],
  );

  return { onSubmit, onSubmitProgress: generateTransitionalCareFormProgress };
};

const useGetFormInputValue = ({
  transitionalCare,
}: {
  transitionalCare: Readonly<TransitionalCare> | undefined;
}): TransitionalCareFormData | null => {
  return useMemo(() => {
    if (!transitionalCare?.form_data) return null;

    const stringified = convertEncryptedFieldIn(
      transitionalCare.form_data as AnyObject,
    );

    if (stringified) {
      return JSON.parse(stringified) as TransitionalCareFormData;
    }

    return null;
  }, [transitionalCare?.form_data]);
};

const validatePatientConfirmation = (
  formInput: TransitionalCareFormData,
  props: { translations: Translations },
) => {
  if (formInput.transitional_care_patient_confirmation_explanation) return true;

  if (!formInput.transitional_care_patient_confirmation)
    return {
      transitional_care_patient_confirmation_explanation: {
        customMessage:
          props.translations.patientForms.transitionalCareForm
            .validationErrorMessage,
      },
    };

  return true;
};

export function TransitionalCareForm({
  auction,
  transitionalCare,
}: {
  auction: Auction;
  transitionalCare: Readonly<TransitionalCare> | undefined;
}) {
  const { onSubmit, onSubmitProgress } = useGetOnSubmit({ auction });
  const formInputValue = useGetFormInputValue({ transitionalCare });

  return (
    <SimpleFormRenderProps
      asHtmlForm
      formInputValue={formInputValue}
      modelDefinition={transitionalCareModelDefinition}
      onSubmit={onSubmit}
      validate={composeValidation(
        validateModel(transitionalCareModelDefinition),
        validatePatientConfirmation,
      )}
    >
      {({ dirty, onChange: formOnChange, submit }) => (
        <ExitPreventionWrapper dirty={dirty}>
          <TransitionalCareFormPresenter
            submit={submit}
            auction={auction}
            onSubmitProgress={onSubmitProgress}
            formOnChange={formOnChange}
            hasPreviousFormValue={!!transitionalCare}
          />
        </ExitPreventionWrapper>
      )}
    </SimpleFormRenderProps>
  );
}
